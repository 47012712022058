<template>
  <section
    v-if="!contentPageInfo.showInvitation"
    :class="$route.name == 'project-task' ? 'main-page pt-3' : ''"
  >
    <div class="row" :class="$route.name == 'project-task' ? 'ms-3 me-3' : ''">
      <div
        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3"
      >
        <Form
          ref="resetForm"
          @submit="formAction(data.inputFormData)"
          v-slot="{ errors }"
          :validation-schema="schema"
        >
          <div class="row scrollable-content">
            <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
              <div class="table-box p-3 mb-3">
                <div
                  class="table-title d-flex flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column mb-3"
                >
                  <div class="mb-3 border-bottom pb-2">
                    <h2 class="mb-2">
                      {{ contentPageInfo.project_name }}
                    </h2>
                    <p>
                      {{ contentPageInfo.identifier }}
                    </p>
                  </div>
                </div>
                <div class="new-template-form">
                  <div class="form-group row mb-3">
                    <label for="" class="form-control-label">{{
                      translations?.insert
                    }}</label>
                    <div class="col-sm-12">
                      <editor
                        v-if="!data.inputFormData.is_empty"
                        :api-key="editorKey"
                        id="editor"
                        :disabled="isDisabled"
                        :init="{
                          element_format: 'html',
                          height: 400,
                          pthManager: siteUrl(
                            `tinymce?project_id=${tinyParm.project_id}&company_id=${tinyParm.company_id}&project_document_item_id=${tinyParm.item_id}`
                          ),
                          menubar: 'insert',
                          branding: false,
                          convert_urls: false,
                          relative_urls: false,
                          image_caption: true,
                          image_advtab: true,
                          min_height: 200,
                          max_height: 650,
                          valid_elements: '+*[*]',
                          pagebreak_separator:
                            '<br style=\'page-break-before:always\'/>',
                          nonbreaking_force_tab: true,
                          content_css: [
                            '/css/paper/paper.min.css?v=' + Math.random(),
                          ],
                          body_id: 'wrapper',
                          plugins: [
                            'autoresize advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen pagebreak',
                            'insertdatetime media table paste code codesample help wordcount preview hr',
                          ],
                          external_plugins: {
                            autocomplete:
                              '/tinymce/plugins/autocomplete/plugin.js',
                            WYImageManager:
                              '/tinymce/plugins/wy_image_manager/plugin.js',
                            glossary: '/tinymce/plugins/glossary/plugin.js',
                            document: '/tinymce/plugins/document/plugin.js',
                            CIManager:
                              '/tinymce/plugins/content_item_filter/plugin.js',
                          },
                          toolbar:
                            'fullscreen | undo redo | formatselect | bold italic backcolor | \
                                                                                                                        alignleft aligncenter alignright alignjustify | WYImageManager | CIManager | \
                                                                                                                        bullist numlist outdent indent | link s | table | glossary | document | removeformat | pagebreak | help',
                        }"
                        v-model="data.inputFormData.content"
                      />
                    </div>
                  </div>
                  <div>
                    <span>
                      <input
                        type="checkbox"
                        aria-checked="true"
                        id=""
                        role="checkbox"
                        value=""
                        class="me-2"
                      />{{ translations?.not_needed }}
                    </span>
                  </div>
                  <div v-if="data.inputFormData.is_empty">
                    <div class="form-group row mb-3">
                      <label for="inputName" class="col-sm-2 form-control-label"
                        >Rational</label
                      >
                      <div class="col-sm-10">
                        <input
                          type="text"
                          class="form-control"
                          id="inputName"
                          placeholder="Rational"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <div class="table-box p-3 mb-3" v-if="data.permission.canEdit">
                <div
                  class="form-group mb-3"
                  v-if="
                    data.inputFormData.status != 'Draft' &&
                    data.inputFormData.canEdit &&
                    data.permission.canEdit
                  "
                >
                  <label for="" class="col-sm-2 form-control-label"
                    >Remarks</label
                  >
                  <div>
                    <textarea class="form-control" rows="2"></textarea>
                  </div>
                </div>

                <approver-button-group
                  :translations="translations"
                  v-if="data.permission.canEdit"
                  :show="
                    data.inputFormData.id == ''
                      ? true
                      : data.inputFormData.canEdit
                  "
                  :can-edit="data.permission.canEdit"
                  :form="data.inputFormData"
                  :groups="{
                    author: contentPageInfo.author,
                    reviewer: contentPageInfo.reviewer,
                    approver: contentPageInfo.approver,
                  }"
                  :submitted="data.approverGroupSubmitted"
                  @submitForm="submitForm"
                />
              </div>
              <div class="table-box p-3 mb-3">
                <p class="border-bottom mb-2 pb-2">
                  {{ translations?.status }}
                  <span class="fw-bold"> {{ data.inputFormData.status }}</span>
                </p>

                <!-- <approver-group

                                  :category="'task/submit'"
                                  :author="contentPageInfo.author"
                                  :reviewer="contentPageInfo.reviewer"
                                  :approver="contentPageInfo.approver"
                                  :id="data.inputFormData.id"
                                  :project_id="tinyParm.project_id"
                                  :status="data.inputFormData.status"
                                  :canEdit="contentPageInfo.canEdit"
                                  :show="['review', 'approve']"
                                  :compName="'task_submit'"
                                  @buttonSuccess="buttonSuccess"
                                  @approverGroupUpdate="approverGroupUpdate"
                                /> -->

                <approver-group
                  :translations="translations"
                  v-if="contentPageInfo.author.lists.length > 0"
                  :author="contentPageInfo.author"
                  :reviewer="contentPageInfo.reviewer"
                  :approver="contentPageInfo.approver"
                  :id="data.inputFormData.id"
                  :project_id="tinyParm.project_id"
                  :canEdit="contentPageInfo.canEdit"
                  :status="data.inputFormData.status"
                  :show="['review', 'approve']"
                  @buttonSuccess="buttonSuccess"
                  @approverGroupUpdate="approverGroupUpdate"
                />
              </div>
              <project-discussion
                :translations="translations"
                :id="content_id"
                :content-page-info="contentPageInfo"
              />

              {{ "test" }}
            </div>
          </div>
        </Form>
      </div>
    </div>

    <BottomFooter v-if="$route.name == 'project-task'"></BottomFooter>
  </section>

  <invite-user
    v-else
    :id="contentPageInfo.invitationData.id"
    :category="contentPageInfo.invitationData.category"
    :compName="contentPageInfo.invitationData.compName"
    :project-id="contentPageInfo.invitationData.project_id"
    :role="contentPageInfo.invitationData.role"
    :role-name="contentPageInfo.invitationData.roleName"
    :type="contentPageInfo.invitationData.type"
    :set-url="contentPageInfo.invitationData.setUrl"
    :params="contentPageInfo.invitationData.setParams"
    :company-id="contentPageInfo.invitationData.company_id"
    @approverGroupSuccess="approverGroupSuccess"
  />
</template>

<script>
import HelperFunction from "@/common/helpers";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, onMounted, reactive, inject } from "vue";
import * as Yup from "yup";
import { Field, Form } from "vee-validate";
import ApproverGroup from "@/common/components/approver-group";
import InviteUser from "@/common/components/invite-user/Index";
import ApproverButtonGroup from "@/common/components/button-groups";
import ProjectDiscussion from "@/views/auth/project/content-item/ProjectDiscussion";
import BottomFooter from "@/views/layouts/auth/common/BottomFooter";

export default {
  name: "ProjectTask",
  components: {
    Field,
    Form,
    ApproverGroup,
    InviteUser,
    ApproverButtonGroup,
    ProjectDiscussion,
    BottomFooter,
  },
  setup() {
    const toast = inject("toast");
    const {
      data,
      editFormData,
      storeFormData,
      resetForm,
      formPermissionResolver,
    } = HelperFunction();
    const route = useRoute();
    const router = useRouter();
    const content_id = route.params.content_id
      ? route.params.content_id
      : route.params.id;
    const store = useStore();
    const profile = store.getters.getProfile;
    const company = store.getters.getSelectedBoard;
    const tinyParm = {
      company_id: company.id,
      project_id: route.params.id,
      item_id: route.params.content_id,
    };
    const editorKey = process.env.VUE_APP_EDITOR_KEY;

    const translations = computed(() => {
      return store.state.translations;
    });

    let contentPageInfo = reactive({
      project_name: "",
      identifier: "",
      canEdit: false,
      contetnOnly: false,
      content_item: {
        explanation: "",
        links: "",
      },
      author: {
        id: "",
        lists: [],
      },
      reviewer: {
        id: "",
        lists: [],
      },
      approver: {
        id: "",
        lists: [],
      },
      showInvitation: false,
      invitationData: null,
    });

    data.setURL = vueConfig.Tasks.TaskEndPoint + "/" + content_id;

    onMounted(() => {
      onMountedCall();
    });

    async function onMountedCall() {
      await editFormData({ url: data.setURL }).then((resp) => {
        const result = resp.data.data;

        contentPageInfo.project_name =
          result.project.project_name.toUpperCase();
        contentPageInfo.identifier = result.content_item.identifier;
        contentPageInfo.canEdit = result.canEdit;
        contentPageInfo.contetnOnly = false;
        contentPageInfo.content_item = {
          explanation: result.content_item.explanation,
          links: result.content_item.links,
        };
        contentPageInfo.author = {
          id: result.author_role_id,
          lists: result.author,
        };
        contentPageInfo.reviewer = {
          id: result.reviewer_id,
          role_name: result.reviewer_role_name,
          lists: result.reviewer,
        };
        contentPageInfo.approver = {
          id: result.approver_id,
          role_name: result.approver_role_name,
          lists: result.approver,
        };
      });
    }

    /** watch & computed **/
    const menuItems = computed(() => store.getters.getMenuItems);
    let selectedMenu = menuItems.value.find((item) => {
      return item.route_link.toLowerCase() == "projects";
    });
    if (selectedMenu) {
      store.dispatch("changeMenu", selectedMenu);
    }

    formPermissionResolver("contents", false, ["update"]);
    /** watch end **/

    const formAction = (inputFormData) => {
      storeFormData({ url: data.setURL }, inputFormData);
    };
    //VALIDATION
    const schema = Yup.object().shape({
      content: Yup.string().required(),
    });

    const siteUrl = (url) => {
      return process.env.VUE_APP_BACKEND_URL + url;
    };

    const approverGroupSuccess = async function (response) {
      if (response != "cancel") {
        response["_method"] = "PUT";
        await storeFormData(
          { url: `${contentPageInfo.invitationData.setUrl}/${content_id}` },
          response
        ).then((resp) => {
          const result = resp.data.data;
          contentPageInfo[result.type].lists = result.users;
        });
      }

      contentPageInfo.showInvitation = false;
      contentPageInfo.invitationData = null;
    };

    const buttonSuccess = function (userType, keyVal, type) {
      if (type === "delete") {
        if (userType === "Author") {
          contentPageInfo.author.lists.splice(keyVal, 1);
        } else if (userType === "Review") {
          contentPageInfo.reviewer.lists.splice(keyVal, 1);
        } else if (userType === "Approve") {
          contentPageInfo.approver.lists.splice(keyVal, 1);
        }
      } else {
        if (userType === "author") {
          contentPageInfo.author.lists[keyVal].status = "Re Invited";
        } else if (userType === "reviewer") {
          contentPageInfo.reviewer.lists[keyVal].status = "Re Invited";
        } else if (userType === "approver") {
          contentPageInfo.approver.lists[keyVal].status = "Re Invited";
        }
      }
    };

    const approverGroupUpdate = function (name, params) {
      contentPageInfo.showInvitation = true;
      contentPageInfo.invitationData = params;
      contentPageInfo.invitationData.role = params.role.join(",");
      contentPageInfo.invitationData.roleName = params.roleName;
      contentPageInfo.invitationData.company_id = company.id;
      contentPageInfo.invitationData.setParams = {
        type: params.type,
        item_id: params.id,
        role_id: contentPageInfo.invitationData.role,
        project_id: params.project_id,
      };
      contentPageInfo.invitationData.setUrl =
        vueConfig.Tasks.TaskInvitationEndPoint;
    };

    const submitForm = async function ($parm, $comment) {
      if (
        contentPageInfo.reviewer.lists.length == 0 &&
        contentPageInfo.approver.lists.length == 0 &&
        $parm != "Assigned"
      ) {
        let toastData = {
          message: "Please add a reviewer and an approver!",
          status: 400,
          data: false,
          response: {
            status: 400,
          },
        };
        toast.error(toastData.message);
      } else {
        data.inputFormData["_method"] = "PUT";
        data.inputFormData.status = $parm;
        data.inputFormData.comment = $comment;

        await storeFormData({ url: data.setURL }, data.inputFormData).then(
          () => {
            data.approverGroupSubmitted = true;
            onMountedCall().then(() => {
              // router.push(`/projects/${route.params.id}/contents`)
            });
          }
        );
      }
    };

    return {
      data,
      editorKey,
      contentPageInfo,
      formAction,
      schema,
      tinyParm,
      siteUrl,
      profile,
      approverGroupUpdate,
      buttonSuccess,
      approverGroupSuccess,
      submitForm,
      resetForm,
      content_id,
      translations,
    };
  },
};
</script>

<style scoped></style>
